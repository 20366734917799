.iq-light, .iq-dark {
  --iq-nav-width: 18rem !important;
}

.no-paddings {
  padding: 0 !important;
}

.iq-header-wrapper, .iq-footer-wrapper {
  right: 0 !important;
}

.iq-window-content {
  .iq-layout-footer {
    box-shadow: none;
    font-size: 40%;
    line-height: 1.2;
    text-transform: uppercase;
  }
}

.qrcode-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > section {
    width: 320px;
    height: 320px;
    border-radius: 0.8rem;
    box-shadow: var(--iq-elevation-2-shadows);
    overflow: hidden;

    > section {
      > div {
        position: absolute;
        left: -10px !important;
        top: -10px !important;
        right: -10px !important;
        bottom: -10px !important;
        width: auto !important;
        height: auto !important;
        box-shadow: var(--iq-accent-secondary) 0 0 0 0.3rem inset !important;
        border-color: var(--iq-backdrop) !important;
        border-width: 40px !important;
        border-radius: 45px;
      }
    }
  }

  @media (max-width: 400px) {
    justify-content: start;

    > section {
      max-width: none;
      margin: -1.6rem -1.6rem 0;
      border-radius: 0;
      box-shadow: none;
      width: 100vw;
      height: 100vw;

      > section {
        > div {
          border-width: 20px !important;
          border-radius: 25px;
        }
      }
    }
  }

  .scan-info {
    margin-top: 2.4rem;
    max-width: 320px;
    width: 100%;
    height: 8rem;

    h1 {
      font-family: monospace;
      text-align: center;
      font-weight: 700;
    }

    h6, p {
      text-align: center;
      color: var(--iq-color-secondary);
    }

    p {
      font-weight: 700;
      color: var(--iq-color);
    }
  }
}

.iq-footer-logo {
  height: 1.8rem;
  width: auto;

  g {
    fill: var(--iq-color-secondary)
  }
}

.iq-body {
  display: flex;
  flex-direction: column;

  > .iq-view-content {
    flex: 1;
  }
}
